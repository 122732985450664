import { ChartOptions } from 'chart.js';
import { AXIS_WIDTH } from './consts';
import { useBarChartStore } from './store';

export const createAxisChartConfig = (
  isStacked: boolean
): ChartOptions<'bar'> => ({
  maintainAspectRatio: false,
  animation: false,
  plugins: {
    legend: {
      display: false,
      labels: {
        font: {
          family: 'Avenir, sans-serif',
          size: 16
        }
      }
    },
    tooltip: {
      enabled: false
    }
  },
  scales: {
    x: {
      stacked: isStacked,
      ticks: {
        display: false
      },
      grid: {
        color: 'transparent'
      },
      afterFit: (ctx) => {
        ctx.height = 60;
      }
    },
    y: {
      beginAtZero: true,
      stacked: isStacked,
      afterFit: (ctx) => {
        ctx.width = AXIS_WIDTH;
      },
      border: {
        display: false
      },
      ticks: {
        callback: (value: number | string) => {
          const precision = value < 9 ? 6 : 2;
          return +(+value as number).toFixed(precision);
        }
      }
    }
  }
});

export const createChartConfig = (
  name: string,
  isStacked: boolean
): ChartOptions<'bar'> => ({
  maintainAspectRatio: false,
  animation: false,
  layout: {
    padding: {
      top: 10
    }
  },
  plugins: {
    legend: {
      display: false,
      labels: {
        font: {
          family: 'Avenir, sans-serif',
          size: 16
        }
      }
    },
    tooltip: {
      enabled: false,
      external: ({ tooltip, chart }) => {
        if (tooltip.opacity === 0) {
          useBarChartStore.getState().setTooltipData(name, undefined);
        } else {
          const { dataIndex, datasetIndex, dataset } = tooltip.dataPoints[0];
          const current = useBarChartStore.getState().getTooltipData(name);
          if (
            dataIndex !== current?.dataIndex ||
            datasetIndex !== current?.datasetIndex
          ) {
            if (tooltip.dataPoints.length) {
              const meta = chart.getDatasetMeta(datasetIndex);
              const rect = meta.data[dataIndex];
              const x = rect.x;
              const y = rect.y;
              const canvas = chart.canvas.getBoundingClientRect();
              const screenX = canvas.left + x;
              const screenY = canvas.top + y;
              useBarChartStore.getState().setTooltipData(name, undefined); // hack to trigger tooltip rerender
              useBarChartStore.getState().setTooltipData(name, {
                dataIndex,
                datasetIndex,
                left: screenX,
                top: screenY,
                value: dataset.data[dataIndex]
              });
            }
          }
        }
      }
    }
  },
  scales: {
    x: {
      stacked: isStacked,
      grid: isStacked ? { offset: false } : { display: false },
      afterFit: (ctx) => {
        ctx.height = 60;
      },
      border: {
        display: false
      },
      ticks: {
        maxRotation: 60,
        minRotation: 60
      }
    },
    y: {
      beginAtZero: true,
      stacked: isStacked,
      ticks: {
        display: false
      },
      border: {
        display: false
      },
      grid: {
        tickLength: 15
      }
    }
  }
});
