import { Box, Flex, Text, UnorderedList } from '@chakra-ui/react';
import { FC, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { updateScrollPosition } from '../../utils';
import { InputField } from '../Fields';
import { Scrollbar } from '../Scrollbar';
import { SearchPlaceholder } from '../SearchPlaceholder';
import { Tooltip } from '../Tooltip';
import { Option, OpenButton, IconButton } from './components';
import { useHandleKeyDown, usePlacementProps } from './hooks';
import {
  MultiselectDropdownOption,
  MultiselectDropdownSize,
  OptionClickHandler
} from './types';

interface MultiselectDropdownProps {
  options: MultiselectDropdownOption[];
  name: string;
  placeholder: string;
  noOptionsLabel?: string;
  value?: MultiselectDropdownOption[];
  defaultValue?: MultiselectDropdownOption[];
  size?: MultiselectDropdownSize;
  renderTooltipLabel?: (index: number) => ReactNode | string | null;
  onChange: (values: MultiselectDropdownOption[]) => void;
  onOpenChange?: (isOpen: boolean) => void;
}

export const MultiselectDropdown: FC<MultiselectDropdownProps> = ({
  options,
  name,
  placeholder,
  noOptionsLabel = 'No items',
  value,
  defaultValue = [],
  size = MultiselectDropdownSize.Md,
  renderTooltipLabel,
  onChange,
  onOpenChange
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<
    Map<string, MultiselectDropdownOption>
  >(new Map(defaultValue.map((v) => [v.value, v])));
  const dropdownRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const listRef = useRef<HTMLUListElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);
  const height = size === MultiselectDropdownSize.Md ? 48 : 40;

  useEffect(() => {
    if (value) {
      setSelectedOptions(new Map(value.map((v) => [v.value, v])));
    }
  }, [value]);

  useEffect(() => {
    onOpenChange?.(isOpen);
    if (isOpen) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const allOptions = useMemo(
    () => [
      { value: 'all', label: 'All' },
      ...(searchValue
        ? options.filter((o) =>
            o.label.toLowerCase().includes(searchValue.toLowerCase())
          )
        : options)
    ],
    [options, searchValue]
  );

  useEffect(() => {
    if (!isOpen) {
      onChange([...selectedOptions.values()]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const getFilteredOptions = () => allOptions.filter((o) => o.value !== 'all');

  const onOptionClick: OptionClickHandler = (checked, option) => {
    const result = new Map(selectedOptions);
    if (checked) {
      result.set(option.value, option);
    } else {
      result.delete(option.value);
    }
    setSelectedOptions(result);
  };

  const onAllClick: OptionClickHandler = (checked) => {
    const result = new Map(selectedOptions);
    const filtered = getFilteredOptions();
    if (checked) {
      filtered.forEach((option) => result.set(option.value, option));
    } else {
      filtered.forEach((option) => result.delete(option.value));
    }
    setSelectedOptions(result);
  };

  const { focusedIndex, handleKeyDown, onClose } = useHandleKeyDown({
    setIsOpen,
    setSearchValue,
    onOptionClick,
    onAllClick,
    dropdownRef,
    inputRef,
    allOptions,
    selectedOptions
  });

  const { listProps, containerProps, placement } = usePlacementProps(
    isOpen,
    dropdownRef,
    panelRef,
    height
  );

  return (
    <Box
      pos="relative"
      ref={dropdownRef}
      boxShadow={isOpen ? '0px 8px 24px rgba(215, 217, 220, 0.4)' : ''}
    >
      <Flex
        h={height + 'px'}
        alignItems="center"
        color="content.secondary"
        bg="background.primary"
        border="1px solid"
        borderRadius="4px"
        borderColor={isOpen ? 'border.selected' : 'border.normal'}
        borderBottomRadius={isOpen ? '0px' : '4px'}
        justify="space-between"
        pl="12px"
        {...containerProps}
      >
        <Box display={isOpen ? 'initial' : 'none'}>
          <InputField
            data-testid={name}
            ref={inputRef}
            border="none"
            name={name}
            p="0"
            m="3px 0"
            h="32px"
            aria-haspopup="listbox"
            aria-expanded={true}
            autoComplete="off"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value || '')}
            onKeyDown={handleKeyDown}
          />
        </Box>
        {isOpen || (
          <OpenButton
            isOpen={isOpen}
            onClick={() => setIsOpen(true)}
            selectedOptions={selectedOptions}
            placeholder={placeholder}
            allOptions={allOptions}
          />
        )}
        {/* )} */}
        <IconButton
          isOpen={isOpen}
          searchValue={searchValue}
          selectedOptions={selectedOptions}
          handleKeyDown={handleKeyDown}
          toggleOpen={() => setIsOpen((prev) => !prev)}
          clearSearch={() => {
            inputRef.current?.focus();
            setSearchValue('');
          }}
          clearSelection={() => {
            onClose();
            setSelectedOptions(new Map());
            onChange([]);
          }}
        />
      </Flex>
      {isOpen && (
        <Box
          ref={panelRef}
          visibility={placement ? 'initial' : 'hidden'}
          overflow="hidden"
          pos="absolute"
          left="0"
          w="100%"
          zIndex="dropdown"
          border="1px solid"
          borderColor="border.selected"
          {...listProps}
        >
          <Scrollbar autoHeightMax="239px" autoHeight>
            {!options.length ? (
              <SearchPlaceholder>
                <Text p="0 16px" textAlign="center">
                  {noOptionsLabel}
                </Text>
              </SearchPlaceholder>
            ) : allOptions.length === 1 ? (
              <SearchPlaceholder />
            ) : (
              <UnorderedList
                ref={listRef}
                m="0"
                __css={{
                  '*:not(:last-child)': {
                    borderBottom: '1px solid',
                    borderColor: 'border.divider'
                  }
                }}
                listStyleType="none"
                role="listbox"
                tabIndex={-1}
                onKeyDown={handleKeyDown}
              >
                {allOptions.map((option, i) => {
                  setTimeout(() => {
                    updateScrollPosition(listRef);
                  }, 50);
                  const isFocused = focusedIndex === i;
                  if (option.value === 'all') {
                    const filtered = getFilteredOptions();
                    const isChecked = filtered.every((o) =>
                      selectedOptions.has(o.value)
                    );
                    const isIndeterminate =
                      filtered.some((o) => selectedOptions.has(o.value)) &&
                      !isChecked;
                    return (
                      <Option
                        key={option.value}
                        option={option}
                        isFocused={isFocused}
                        isChecked={isChecked}
                        isIndeterminate={isIndeterminate}
                        onOptionClick={onAllClick}
                        optionProps={{
                          pos: 'sticky',
                          top: '0',
                          zIndex: 'docked'
                        }}
                      />
                    );
                  }
                  return (
                    <Tooltip
                      openDelay={500}
                      key={option.value}
                      maxW="fit-content"
                      placement="top"
                      isDisabled={!renderTooltipLabel}
                      label={renderTooltipLabel?.(i - 1)}
                    >
                      <Box>
                        <Option
                          option={option}
                          isFocused={isFocused}
                          isChecked={selectedOptions.has(option.value)}
                          onOptionClick={onOptionClick}
                        />
                      </Box>
                    </Tooltip>
                  );
                })}
              </UnorderedList>
            )}
          </Scrollbar>
        </Box>
      )}
    </Box>
  );
};
