import { DateRangeType, ReportObject, Dimension, Granularity } from './types';

export const dateRangeTypeOptions = [
  {
    label: 'Current month',
    value: DateRangeType.CurrentMonth
  },
  {
    label: 'Previous month',
    value: DateRangeType.PreviousMonth
  },
  {
    label: 'Previous 3 months',
    value: DateRangeType.Previous3Months
  },
  {
    label: 'Current year',
    value: DateRangeType.CurrentYear
  }
];

export const reportObjectOptions = [
  {
    label: 'All',
    value: ReportObject.All
  },
  {
    label: 'Datasets',
    value: ReportObject.Datasets
  },
  {
    label: 'Experiments',
    value: ReportObject.Experiment
  }
];

export const horizontalTableLabel: Record<DateRangeType, Granularity> = {
  [DateRangeType.CurrentMonth]: 'Days',
  [DateRangeType.PreviousMonth]: 'Days',
  [DateRangeType.Previous3Months]: 'Days',
  [DateRangeType.CurrentYear]: 'Months'
};

export const dimensionLabels: Record<Dimension, string> = {
  [Dimension.Storage]: 'Storage usage (Gb months)',
  [Dimension.Traffic]: 'Egress data traffic (Gb)'
};

export const tooltipLabels: Record<Dimension, string> = {
  [Dimension.Storage]: 'Gb months',
  [Dimension.Traffic]: 'Gb'
};

export const COLORS = [
  '#FD7F6F',
  '#7EB0D5',
  '#B2E061',
  '#BD7EBE',
  '#FFB55A',
  '#FFF180',
  '#BEB9DB',
  '#FDCCE5',
  '#8BD3C7',
  '#FFA07A',
  '#757575'
];

export const HOVER_COLORS = [
  '#FD523D',
  '#539ED5',
  '#8DCF1B',
  '#BC57BE',
  '#FF920D',
  '#F7DE11',
  '#988CDB',
  '#FD81C0',
  '#59C2B0',
  '#E56F40',
  '#454545'
];

export const TOP_OBJECTS_AMOUNT = 10;

export const OTHER_ENTITIES = 'other';
