import { Box, Flex, StackProps, Text, VStack } from '@chakra-ui/react';
import { FC, memo, useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { BarTooltip } from './components';
import { createAxisChartConfig, createChartConfig } from './config';
import { AXIS_WIDTH } from './consts';
import {
  BarChartColors,
  BarChartDataset,
  BarParams,
  TooltipData
} from './types';

interface BarChartProps {
  name: string;
  labels: string[];
  datasets: BarChartDataset[];
  h: number;
  w: number;
  barColors: BarChartColors;
  isStacked: boolean;
  horizontalLabel?: string;
  verticalLabel?: string;
  containerProps?: StackProps;
  renderTooltip?: (data: TooltipData) => React.ReactNode;
  getBarParams: (isStacked: boolean, datasetsAmount: number) => BarParams;
}

const Component: FC<BarChartProps> = ({
  name,
  labels,
  datasets,
  h,
  w,
  barColors,
  isStacked,
  horizontalLabel,
  verticalLabel,
  containerProps,
  renderTooltip,
  getBarParams
}) => {
  const height = `${h}px`;
  const width = `${w}px`;

  const { barThickness, borderWidth, barWidth, offset, spacing } = getBarParams(
    isStacked,
    datasets.length
  );

  const getChartWidth = () => {
    const barsAmount = labels.length;
    const totalSpacing = (spacing - offset) * barsAmount;
    const totalBarWidth = barWidth * barsAmount;
    const totalContentWidth = totalBarWidth + totalSpacing - AXIS_WIDTH;
    const availableWidth = w - (verticalLabel ? 24 : 0) - AXIS_WIDTH;

    return Math.max(totalContentWidth, availableWidth);
  };

  const data = useMemo(
    () =>
      datasets.map((d) => ({
        data: d.values,
        barThickness,
        borderWidth,
        backgroundColor: barColors[d.uuid]?.main,
        hoverBackgroundColor: barColors[d.uuid]?.hover,
        borderColor: 'transparent',
        skipNull: true
      })),
    [barColors, barThickness, borderWidth, datasets]
  );

  return (
    <VStack
      spacing="0"
      align="start"
      w="fit-content"
      pos="relative"
      {...containerProps}
    >
      <Box>
        <Flex w={width}>
          <Flex h={height} pos="relative" pl={verticalLabel ? '24px' : 0}>
            {verticalLabel && (
              <Text
                w="100%"
                textAlign="center"
                variant="captionHigh"
                color="content.additionalTablet"
                top="40%"
                css={{
                  rotate: '-90deg'
                }}
                left="-25px"
                pos="absolute"
                whiteSpace="nowrap"
                display="flex"
                justifyContent="center"
              >
                {verticalLabel}
              </Text>
            )}
            <Box h={height} w={`${AXIS_WIDTH}px`}>
              <Bar
                data={{
                  labels,
                  datasets: data
                }}
                options={createAxisChartConfig(isStacked)}
              />
            </Box>
          </Flex>
          <Box display="grid">
            <Box maxW={width} overflowX="auto">
              <Box h={height} w={`${getChartWidth()}px`}>
                <Bar
                  data={{
                    labels,
                    datasets: data
                  }}
                  options={createChartConfig(name, isStacked)}
                />
              </Box>
            </Box>
            {horizontalLabel && (
              <Text
                pt="8px"
                w="100%"
                textAlign="center"
                variant="captionHigh"
                color="content.additionalTablet"
              >
                {horizontalLabel}
              </Text>
            )}
          </Box>
        </Flex>
      </Box>
      <BarTooltip name={name} renderTooltip={renderTooltip} />
    </VStack>
  );
};

export const BarChart = memo(Component);
