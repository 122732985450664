import { FC } from 'react';
import { Tooltip } from '../../../Tooltip';
import { useBarChartStore } from '../../store';
import { TooltipData } from '../../types';

interface BarTooltipProps {
  name: string;
  renderTooltip?: (data: TooltipData) => React.ReactNode;
}

export const BarTooltip: FC<BarTooltipProps> = ({ name, renderTooltip }) => {
  const tooltips = useBarChartStore((state) => state.tooltips);
  const tooltip = tooltips[name];

  if (!tooltip || !renderTooltip) {
    return null;
  }

  return (
    <Tooltip
      openDelay={100}
      maxW="auto"
      hasArrow
      label={renderTooltip(tooltip)}
      placement="top"
      isOpen
    >
      <div
        style={{
          position: 'fixed',
          transform: 'translate(-50%, -100%)',
          transition: 'all .1s ease',
          top: tooltip.top,
          left: tooltip.left
        }}
      />
    </Tooltip>
  );
};
